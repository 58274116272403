
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































































































































































































































































































































.personality-360 {
  position: relative;
}

.personality-360__title {
  padding-right: 1em;

  ::v-deep em {
    color: $c-gray-dark;
  }

  @include mq($until: m) {
    ::v-deep br {
      display: none;
    }
  }
}

.personality-360-inner {
  position: relative;
  margin-bottom: $spacing * 4;

  @include mq(m) {
    margin-bottom: 0;
  }
}

.personality-360__container {
  @extend %border-radius-small;

  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;

  ::v-deep canvas {
    height: 100%;
  }
}

.personality-360__logo {
  position: absolute;
  right: $spacing;
  bottom: $spacing;
  color: $c-medium-grey;

  @include mq(l) {
    right: $spacing * 2;
    bottom: $spacing * 2;

    .icon {
      width: 8rem;
      height: auto;
    }
  }
}

.personality-360__controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  background: $c-white;
  border-radius: 4rem;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, 120%);

  @include mq(m) {
    transform: translate(-50%, 50%);
  }
}

.personality-360__controls__direction {
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  height: 6rem;
  padding: 0 $spacing * 0.5;

  @include mq(l) {
    height: 7rem;
    gap: 0;
  }
}

.personality-360__controls__direction__top {
  @include center-x;

  top: 0;

  .icon {
    transform: rotate(90deg);
  }
}

.personality-360__controls__direction__bottom {
  @include center-x;

  bottom: 0;

  .icon {
    transform: rotate(-90deg);
  }
}

.personality-360__controls__zoom {
  display: flex;
  align-items: center;
  padding: 0 $spacing * 0.5 0 $spacing * 0.4;
  border-left: 1px solid $c-light-grey;
}

.personality-360__controls__button {
  @extend %button-nostyle;

  color: $c-mustard;
  transition: color 0.5s $ease-softer;

  .icon {
    width: 3rem;
  }

  &:hover,
  &:focus-visible {
    color: $c-black;
  }

  @include mq(l) {
    .icon {
      width: 3.6rem;
    }
  }
}

.personality-360__info {
  @extend %shadow;

  position: fixed;
  z-index: 1000; // over header
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 50rem;
  height: 100%;
  padding: $spacing $spacing $spacing 0;
  background: $c-white;
  border-radius: 0.8rem 0 0 0.8rem;

  @include mq(l) {
    width: 40vw;
    padding: $spacing $spacing $spacing * 2 0;
  }
}

.personality-360__info__button {
  @extend %button-nostyle;

  align-self: flex-end;
  margin-bottom: $spacing * 1.5;
  transition: color 0.2s;

  span {
    @extend %visually-hidden;
  }

  &:hover,
  &:focus-visible {
    color: $c-mustard;
  }

  @include mq(l) {
    margin-right: -0.4rem;
  }
}

.personality-360__info__button__label {
  @extend %visually-hidden;
}

.personality-360__info__content {
  @include scrollbars(0.8rem, $c-gray-medium, $c-gray-lighter);

  overflow-y: auto;
  height: 100%;
  padding: 0 $spacing * 2;

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 0.8rem;
  }
}

.personality-360__info__title {
  margin-bottom: $spacing;
}

.personality-360__info__picture,
.personality-360__info__text ::v-deep img {
  @extend %shadow;

  margin: $spacing auto;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 4rem rgb(0 0 0 / 10%);
}

.personality-360__info__link {
  flex-shrink: 0;
}
